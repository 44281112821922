import React from "react";

const Navigation = () => {

    return (
        <nav className="navbar navbar-default">
            <div className="container">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#nav-icon-collapse" aria-expanded="false">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    </button>

                    <a className="logo" href="#">Myers Running Camp</a>
                </div>

                <div className="collapse navbar-collapse" id="nav-icon-collapse">  
                    <ul className="nav navbar-nav navbar-right">
                        <li><a href="#" data-scroll-nav="0" className="active">Home</a></li>
                        <li><a href="#" data-scroll-nav="1">About</a></li>
                        <li><a href="#" data-scroll-nav="2">Registration</a></li>
                        <li><a href="#" data-scroll-nav="3">Past Camps</a></li>
                        <li><a href="#" data-scroll-nav="4">Directors</a></li>
                        <li><a href="#" data-scroll-nav="5">Numbers</a></li>
                        <li><a href="#" data-scroll-nav="7">Sponsors</a></li>
                        <li><a href="#" data-scroll-nav="6">Contact</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    )
};

export default Navigation;