import React from "react";

const Header = () => {

    return (
        <section id="home" className="header" data-scroll-index="0" style={{backgroundImage: "url(assets/img/bg.jpg)"}} data-stellar-background-ratio="0.8">

			<div className="v-middle">
				<div className="container">
					<div className="row">

						<div className="caption">
							<h5>Hello!</h5>
							<h1 className="cd-headline clip">
					            {/* <span className="blc">This is </span> */}
					            <span className="cd-words-wrapper">
									<b className="is-visible">This is Myers Running Camp</b>
									<b>We are Chad, Ben, Rob Myers</b>
									<b>Camp is July 10-13, 2025</b>
					            </span>
			          		</h1>

			          		<div className="social-icon">
			          			<a href="https://www.facebook.com/groups/132479596768153">
			          				<span><i className="fa fa-facebook" aria-hidden="true"></i></span>
			          			</a>
			          			<a href="https://www.youtube.com/@myersrunningcamp3069">
			          				<span><i className="fa fa-youtube" aria-hidden="true"></i></span>
			          			</a>
			          		</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
};

export default Header;