import React from "react";

const Numbers = () => {

    return (
        <div className="numbers section-padding text-center pb-70" data-scroll-index="5">
			<div className="container">
				<div className="row">

					<div className="col-md-3">
						<div className="item">
							<span className="icon"><i className="fa fa-calendar" aria-hidden="true"></i></span>
							<h3 className="counter">14</h3>
							<p>Years of Camp</p>
						</div>
					</div>

					<div className="col-md-3">
						<div className="item">
							<span className="icon"><i className="fa fa-user-plus" aria-hidden="true"></i></span>
							<h3 className="counter">1000+</h3>
							<p>Campers</p>
						</div>
					</div>

					<div className="col-md-3">
						<div className="item">
							<span className="icon"><i className="fa fa-users" aria-hidden="true"></i></span>
							<h3 className="counter">100+</h3>
							<p>Teams</p>
						</div>
					</div>

					<div className="col-md-3">
						<div className="item">
							<span className="icon"><i className="fa fa-map-marker" aria-hidden="true"></i></span>
							<h3 className="counter">10+</h3>
							<p>States</p>
						</div>
					</div>

				</div>
			</div>
		</div>
    )
};

export default Numbers;