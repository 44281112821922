import React from "react";
import PhotoAlbum from "react-photo-album";

const Photos = () => {
    return (
        <section class="portfolio section-padding pb-70" data-scroll-index="3">
			<div class="container">
				<div class="row">

					<div class="section-head">
						<h3>Past Camps.</h3>
					</div>

					<iframe className="camp-videos" src="https://www.youtube.com/embed/iSP8iaxa-HY?si=0lL6cdTnqcfQrUaH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe><br />
					<iframe className="camp-videos" src="https://www.youtube.com/embed/PhCZ6zhT4Ps?si=s9WaXo0jLDcO7fZu" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe><br />
					<iframe className="camp-videos" src="https://www.youtube.com/embed/vpwMhvN8Urk?si=1d61pi_m9rh4zDMd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
				</div>
			</div>
		</section>
    )
};

export default Photos;