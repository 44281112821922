import React from "react";

const Sponsors = () => {

    return (
        <section className="clients section-padding bg-gray" data-scroll-index="7">
			<div className="container">
				<div className="row">

					<div className="section-head">
						<h3>Camp Sponsors.</h3>
					</div>

=					<div className="col-md-offset-1 col-md-10">
						<div className="owl-carousel owl-theme text-center">

							<div className="citem">
								<div className="author-img">
									<img src="assets/img/CRClargelogo.png" alt="Columbus Running Company Logo" />
								</div>
								<h6>Columbus Running Company</h6>
								<a href="https://columbusrunning.com/" target="_blank">Visit Columbus Running Company</a>
							</div>

							<div className="citem">
								<div className="author-img">
									<img src="assets/img/saucony.png" alt="Saucony Logo" />
								</div>
								<h6>Saucony</h6>
								<a href="https://www.saucony.com/en/home" target="_blank">Visit Saucony</a>
							</div>

						</div>
					</div>
				</div>
			</div>
		</section>
    )
};

export default Sponsors;