import React from "react";

const Directors = () => {

    return (
        <section className="clients section-padding bg-gray" data-scroll-index="4">
			<div className="container">
				<div className="row">

					<div className="section-head">
						<h3>Camp Directors.</h3>
					</div>

=					<div className="col-md-offset-1 col-md-10">
						<div className="owl-carousel owl-theme text-center">

							<div className="citem">
								<div className="author-img">
									<img src="assets/img/RobMyers.jpg" alt="Photo of Rob Myers" />
								</div>
								<h6>Rob Myers</h6>
								<p>Rob Myers - The Ohio State University - 3 Time Indoor 1500m USA Champion, 1500m Big Ten Champion, NCAA All-American, 3 Time Ohio High School State Champion, Professional runner with Sacuony, 3:34 1500m PR.</p>
							</div>

							<div className="citem">
								<div className="author-img">
									<img src="assets/img/chad-myers.jpg" alt="Photo of Chad Myers" />
								</div>
								<h6>Chad Myers</h6>
								<p>Chad Myers - Otterbein College - All-Ohio D3 10,000m Champion, OAC 10,000m Champion, NCAA Cross Country Qualifier.</p>
							</div>

							<div className="citem">
								<div className="author-img">
									<img src="assets/img/ben-myers.jpg" alt="Photo of Ben Myers" />
								</div>
								<h6>Ben Myers</h6>
								<p>Ben Myers - Ohio University - 3,000m Steeplechase MAC Champion, 2 Time Ohio High School State Runner Up</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
};

export default Directors;