import React from "react";

const Contact = () => {

    return (
        <section class="contact section-padding" data-scroll-index="6">
			<div class="container">
				<div class="row">
					
					<div class="section-head">
						<h3>Contact Us.</h3>
					</div>

					<div class="col-md-offset-1 col-md-10">

						<div class="info text-center mb-50">

							<div class="col-md-4">
								<div class="item">
									<span class="icon"><i class="fa fa-location-arrow" aria-hidden="true"></i></span>
									<h6>Address</h6>
									<p>Camp Oty'Okwa - 24799 Purcell Rd. South Bloomingville, OH US 43152</p>
									<a href="https://www.google.com/maps/place/24799+Purcell+Rd,+South+Bloomingville,+OH+43152/@39.442026,-82.5757309,603m/data=!3m1!1e3!4m15!1m8!3m7!1s0x8847953a739b0ab3:0x1661b5ced9d7a14a!2s24799+Purcell+Rd,+South+Bloomingville,+OH+43152!3b1!8m2!3d39.442026!4d-82.573156!16s%2Fg%2F11csdgc9rn!3m5!1s0x8847953a739b0ab3:0x1661b5ced9d7a14a!8m2!3d39.442026!4d-82.573156!16s%2Fg%2F11csdgc9rn?entry=ttu&g_ep=EgoyMDI0MTEyNC4xIKXMDSoASAFQAw%3D%3D" target="_blank">Google Maps Location</a>
								</div>
							</div>

							<div class="col-md-4">
								<div class="item">
									<span class="icon"><i class="fa fa-envelope" aria-hidden="true"></i></span>
									<h6>Email</h6>
									<p>myersrunningcamp@gmail.com</p>
								</div>
							</div>

							<div class="col-md-4">
								<div class="item">
									<span class="icon"><i class="fa fa-phone" aria-hidden="true"></i></span>
									<h6>Phone</h6>
									<p>614-581-2191</p>
								</div>
							</div>
							<div class="clearfix"></div>

							<a href="https://runsignup.com/Race/OH/SouthBloomingville/MyersRunningCamp" target="_blank">
								<span className="buton">Register Today!</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
};

export default Contact;