import React from "react";

const Hero = () => {

    return (
        <section className="hero section-padding pb-70" data-scroll-index="1">
			<div className="container">
				<div className="row">

					<div className="col-md-5">
						<div className="hero-img mb-30">
							<img src="assets/img/hero.jpg" alt="" />
						</div>
					</div>

					<div className="col-md-7">
						<div className="content mb-30">
							<h3>About Myers Running Camp</h3>
							
							<br />
							<span className="sub-title">Camp Description</span>
							<p><b>Myers Running Camp</b> will be held on July 10-13, 2025. Our refund policy is something we have to adhere to strictly. It is posted below. This will be an overnight camp, located at Camp Oty’Okwa near Old Mans Cave in beautiful Hocking Hills, Ohio. The camp is open to all students entering grades 7-12 in the fall of 2025.</p>
							
							<br />
							<span className="sub-title">Camp Mission</span>
							<p><b>Myers Running Camp</b> is a camp for mid-distance and distance runners. Our mission is to help young athletes involved in track & field and cross country improve their technique and overall training performance. Over the years, we have had the unique opportunity to be involved in these sports at all levels, high school through international, as both athletes and coaches. Now, we would like to share our experiences and assist other aspiring athletes on their athletic journeys.</p>
							
							<br />
							<span className="sub-title">Camp Goals</span>
							<p><b>Myers Running Camp</b> Our goals will be accomplished through a “learning and training” approach to running. Campers will be introduced to various training methods and drills used by elite athletes and then have the opportunity to practice those techniques alongside high school, college, and elite athletes.</p>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
};

export default Hero;