import React from "react";

const Registration = () => {

    return (
        <section className="services section-padding bg-gray text-center pb-70" data-scroll-index="2">
			<div className="container">
				<div className="section-head">
					<h3>Registration Information.</h3>
				</div>

				<div className="row">
					<div className="col-md-4">
						<div className="item">
							<span className="icon"><i className="fa fa-user" aria-hidden="true"></i></span>
							<h6>Individual Registration</h6>
							<p>Early Registraion<br />11/14/24 - 6/9/25 - $350.00</p><br />
							<p>Late Registraion<br />6/10/25 - 7/7/25 - $375.00</p><br />
							<p>Last Minute Registraion<br />7/8/25 - 9/9/25 - $400.00</p><br />
						</div>
					</div>
					<div className="col-md-4">
						<div className="item">
							<span className="icon"><i className="fa fa-users" aria-hidden="true"></i></span>
							<h6>Team Registration</h6>
							<p>Early Registraion<br />11/14/24 - 6/9/25 - $335.00</p><br />
							<p>Late Registraion<br />6/10/25 - 7/7/25 - $360.00</p><br />
							<p>Last Minute Registraion<br />7/8/25 - 9/9/25 - $385.00</p><br />
						</div>
					</div>
					<div className="col-md-4">
						<div className="item">
							<span className="icon"><i className="fa fa-sign-in" aria-hidden="true"></i></span>
							<h6>Register Now</h6>
							<a href="https://runsignup.com/Race/OH/SouthBloomingville/MyersRunningCamp" target="_blank">
								<span className="buton">Register Today!</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
};

export default Registration;