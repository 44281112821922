import './App.css';
import Navigation from "./components/Navigation";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Registration from "./components/Registration";
import Photos from "./components/Portfolio";
import Directors from "./components/Directors";
import Numbers from "./components/Numbers";
import Contact from "./components/Contact";
import Sponsors from './components/Sponsors';
import Footer from "./components/Footer";

const App = () => {
  return (
    <div className="App">
      <Navigation />
      <Header />
      <Hero />
      <Registration />
      <Photos />
      <Directors />
      <Numbers />
      <Contact />
      <Sponsors />
      <Footer />
    </div>
  );
}

export default App;
