import React from "react";

const Footer = () => {

    return (
        <footer>
			<div className="container text-center">
				<div className="row">
					<p>Copy Right 2024 &copy; By Chad Myers All Rights Reserved</p>
				</div>
			</div>
		</footer>
    )
};

export default Footer;